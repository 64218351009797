.actionFormContainer {
    box-shadow: -7.9px -6.2px 9.4px 0.6px rgba(23, 31, 31, 0.21), 8.1px 5.9px 9.4px 0.6px #193d44;
    border-radius: 0px;
}

.actionFormContainer__form {
    width: 100%;
    min-height: 500px;
    background-color: #e6f4f2;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    .inner-form {
        padding: 30px 20px;
    }
}

.actionFormContainer {
    position: relative;
}

.icon-arrow {
    // background-image: url('../../styles/assets/arrow.png');
    background-image: url('../../styles/assets/arrow-final_longpause.gif');
    background-repeat: no-repeat;
    background-size: contain;
    width: 400px;
    height: 150px;
    position: absolute;
    top: -140px;
    left: 140px;

    @media (max-width: 920px){
        display: none;

    }
}
.HeadlineAGB {    font-family: Gotham, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.HeadlineAGB {
    font-family: Gotham, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.switch {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 34px;
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #15313f;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #224242;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(90px);
    -ms-transform: translateX(90px);
    transform: translateX(86px);
  }
  
  /*------ ADDED CSS ---------*/
  .on
  {
    display: none;
  }
  
  .on, .off
  {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
  }
  
  input:checked+ .slider .on
  {display: block;}
  
  input:checked + .slider .off
  {display: none;}
  
  /*--------- END --------*/
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;}