.impressum__Container{
    color: white;
    font-size: 15px;
    font-weight: lighter;
}

@media (max-width: 650px){

    .impressum__Container{
        width: 80%;
        margin: auto;
    }

}
